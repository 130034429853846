import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { Link } from 'gatsby';

import { colors, media, spacing } from '@styles/Theme';

const scaffoldLink = css`
	display: inline-block;
	text-decoration: none;
	transition: color 0.2s linear;

	span {
		display: flex;
		align-items: center;
	}

	svg {
		width: ${spacing(3)};
		margin-right: ${spacing(1)};
	}
`;

const linkColor = light => css`
	color: ${light ? 'white' : colors.primary};

	&:hover {
		color: ${light ? colors.lightGrey : shade(0.2, colors.primary)};
	}
`;

export const ExternalLink = styled.a`
	${scaffoldLink};
	${({ light }) => linkColor(light)};

	@media (${media.tablet}) {
		&[href^='tel'] {
			cursor: default;
			pointer-events: none;
		}
	}
`;

export const InnerLink = styled(Link)`
	${scaffoldLink};
	${({ light }) => linkColor(light)};
`;
