import {
	CLOSE_NAV,
	SET_CONTACTS,
	TOGGLE_MODAL,
	TOGGLE_NAV,
	HIDE_APPOINTMENT_BUTTON,
	SHOW_APPOINTMENT_BUTTON,
} from './actions';

export const reducer = (state, action) => {
	const { payload } = action;

	switch (action.type) {
		case TOGGLE_NAV:
			return {
				...state,
				isNavOpened: !state.isNavOpened,
			};
		case TOGGLE_MODAL:
			return {
				...state,
				isModalOpened: !state.isModalOpened,
			};
		case CLOSE_NAV:
			return {
				...state,
				isNavOpened: false,
			};
		case SET_CONTACTS: {
			return {
				...state,
				contacts: payload,
			};
		}
		case HIDE_APPOINTMENT_BUTTON: {
			return {
				...state,
				showAppointmentButton: false,
			};
		}
		case SHOW_APPOINTMENT_BUTTON: {
			return {
				...state,
				showAppointmentButton: true,
			};
		}

		default:
			throw new Error(`Unexpected action`);
	}
};
