import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import searchSwitcher from '@utils/searchSwitcher';

import { LoaderIcon } from '@components/Icons';

import * as S from './styles';

const InputSearchList = ({ result, term, loading, notFound }) => {
	const node = useRef();

	const [open, setOpen] = useState(false);

	const handleClickOutside = useCallback(e => {
		if (!node.current.contains(e.target)) {
			setOpen(false);
		}
	}, []);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	useEffect(() => {
		if (term.length) {
			setOpen(true);
		} else {
			setOpen(false);
		}
	}, [term]);

	useEffect(() => {
		if (open) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [open, handleClickOutside]);

	return (
		<S.Container ref={node} open={open} loading={loading ? 'true' : undefined}>
			{loading ? (
				<S.Loading>
					<LoaderIcon />
					<span>Buscando...</span>
				</S.Loading>
			) : (
				<>
					{notFound ? (
						<S.NotFound>
							Nenhum resultado para <b>{term}</b>
						</S.NotFound>
					) : (
						!!result.length &&
						result.map(({ _source: { slug, type_slug, title }, highlight }) => (
							<S.Item
								key={slug}
								to={`${searchSwitcher(type_slug, slug).slug}`}
								onClick={handleClose}
							>
								<span
									dangerouslySetInnerHTML={{
										__html: highlight?.title || title,
									}}
								/>
								<small>
									em: <b>{searchSwitcher(type_slug, slug).title}</b>
								</small>
							</S.Item>
						))
					)}
				</>
			)}
		</S.Container>
	);
};

InputSearchList.propTypes = {
	loading: PropTypes.bool.isRequired,
	notFound: PropTypes.bool.isRequired,
	result: PropTypes.arrayOf(
		PropTypes.shape({
			_source: PropTypes.objectOf(PropTypes.any),
			highlight: PropTypes.objectOf(PropTypes.any),
		})
	).isRequired,
	term: PropTypes.string.isRequired,
};

export default InputSearchList;
