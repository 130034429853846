import React, { useCallback } from 'react';
import { Link } from 'gatsby';

import useGlobal from '@hooks/useGlobal';
import { CLOSE_NAV, TOGGLE_NAV } from '@reducers/actions';

import NavToggle from '@components/Header/NavToggle';
import Button, { ButtonGroup } from '@components/Button';

import AppointmentButton from '@components/Button/AppointmentButton';
import * as S from './styles';

const menuItems = [
	{ link: '/como-funciona', label: 'Como funciona' },
	{ link: '/condicoes-clinicas', label: 'Condições clínicas' },
	{ link: '/medicamentos', label: 'Medicamentos' },
	{ link: '/convenios', label: 'Convênios' },
	{ link: '/atendimento-movel', label: 'Atendimento móvel' },
	{ link: '/unidades', label: 'Unidades' },
	{ link: '/contato', label: 'Contato' },
	{ link: '/blog', label: 'Blog' },
	{ link: '/perguntas-frequentes', label: 'FAQ' },
];

const Nav = () => {
	const [state, dispatch] = useGlobal();

	const handleCloseMenu = useCallback(() => {
		dispatch({ type: CLOSE_NAV });
	}, [dispatch]);

	const handleToggleMenu = useCallback(() => {
		dispatch({ type: TOGGLE_NAV });
	}, [dispatch]);

	return (
		<>
			<NavToggle onClick={() => handleToggleMenu()} />
			<S.Container isOpen={state.isNavOpened}>
				<S.MenuHeader>
					<S.LogoContainer>
						<S.Logo to="/" onClick={handleCloseMenu} />
					</S.LogoContainer>
					<ButtonGroup>
						<Button
							to="https://resultados.fleury.com.br/Cliente/"
							outlined
							small
							inverted
						>
							Resultados
						</Button>
						<AppointmentButton small inverted />
					</ButtonGroup>
				</S.MenuHeader>

				<S.Menu as="ul">
					{menuItems.map(item => (
						<S.MenuItem key={item.label} isOpen={state.isNavOpenend}>
							<Link
								to={item.link}
								activeClassName="active"
								onClick={handleCloseMenu}
							>
								{item.label}
							</Link>
						</S.MenuItem>
					))}
				</S.Menu>
			</S.Container>
		</>
	);
};

export default Nav;
