import React from 'react';

import useGlobal from '@hooks/useGlobal';

import * as S from './styles';

const NavToggle = props => {
	const [state] = useGlobal();

	return (
		<S.Container className={state.isNavOpened && 'open'} {...props}>
			<span />
			<span />
			<span />
			<span />
		</S.Container>
	);
};

export default NavToggle;
