import React, { useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import useGlobal from '@hooks/useGlobal';

import {
	ChevronUpIcon,
	FacebookIcon,
	InstagramIcon,
	PhoneIcon,
	WhatsappIcon,
	YoutubeIcon,
} from '@components/Icons';

import Link from '@components/Link';

import * as S from './styles';

const Footer = () => {
	const [state] = useGlobal();
	const {
		contacts: { phone, whatsapp, facebook, instagram, youtube },
	} = state;

	const handleScrollTop = useCallback(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, []);

	const { allCosmicjsHomepage } = useStaticQuery(graphql`
		{
			allCosmicjsHomepage(filter: { slug: { eq: "responsavel-tecnico" } }) {
				edges {
					node {
						id
						slug
						title
						metadata {
							hat
							title
							description
							nome
						}
					}
				}
			}
		}
	`);

	const technicalManager = allCosmicjsHomepage.edges[0].node.metadata.nome;

	return (
		<S.Container>
			<S.ToTop onClick={handleScrollTop}>
				<ChevronUpIcon size={24} />
				Ir para o topo
			</S.ToTop>
			<S.Content>
				<S.LogoContainer>
					<S.Logo />
					<p>A gente cuida, você confia</p>
				</S.LogoContainer>

				<S.MenuContainer>
					<S.MenuColum>
						<S.MenuTitle>Fale Conosco</S.MenuTitle>
						<S.MenuContent>
							{phone && (
								<S.MenuItem>
									<PhoneIcon size={22} />
									<Link to={phone.link} label={phone.label} external light />
								</S.MenuItem>
							)}

							{whatsapp && (
								<S.MenuItem>
									<WhatsappIcon size={22} />
									<Link
										to={whatsapp.link}
										label={whatsapp.label}
										external
										light
									/>
								</S.MenuItem>
							)}
						</S.MenuContent>
					</S.MenuColum>

					<S.MenuColum>
						<S.MenuTitle>Redes sociais</S.MenuTitle>
						<S.MenuContent>
							{instagram && (
								<S.MenuItem>
									<InstagramIcon size={22} />
									<Link
										to={instagram.link}
										label={instagram.label}
										external
										light
									/>
								</S.MenuItem>
							)}

							{facebook && (
								<S.MenuItem>
									<FacebookIcon size={22} />
									<Link
										to={facebook.link}
										label={facebook.label}
										external
										light
									/>
								</S.MenuItem>
							)}

							{youtube && (
								<S.MenuItem>
									<YoutubeIcon size={22} />
									<Link
										to={youtube.link}
										label={youtube.label}
										external
										light
									/>
								</S.MenuItem>
							)}
						</S.MenuContent>
					</S.MenuColum>

					<S.MenuColum>
						<S.MenuTitle>Links úteis</S.MenuTitle>
						<S.MenuContent>
							<S.MenuItem>
								<Link
									to="/perguntas-frequentes"
									label="Perguntas frequentes"
									light
								/>
							</S.MenuItem>

							<S.MenuItem>
								<Link
									to="/condicoes-clinicas"
									label="Condições clínicas"
									light
								/>
							</S.MenuItem>

							<S.MenuItem>
								<Link to="/medicamentos" label="Medicamentos" light />
							</S.MenuItem>

							<S.MenuItem>
								<Link to="/atendimento-movel" label="Atendimento móvel" light />
							</S.MenuItem>

							<S.MenuItem>
								<Link
									to="https://portal.privacytools.com.br/portal/2f77be0d-7368-4fbe-bd0f-d128d7617b4a/"
									label="Portal de privacidade"
									external
									light
								/>
							</S.MenuItem>

							<S.MenuItem>
								<Link
									to="https://trabalheconosco.vagas.com.br/grupo-fleury"
									label="Trabalhe conosco"
									external
									light
								/>
							</S.MenuItem>
						</S.MenuContent>
					</S.MenuColum>
				</S.MenuContainer>
			</S.Content>

			<S.FooterInformation>
				Fleury S.A. | CNPJ: 60.840.055/0001-31
			</S.FooterInformation>
			<S.FooterInformation>
				Av. Santo Amaro, 4584, CEP 04701-200 - São Paulo, SP
			</S.FooterInformation>
			<S.FooterInformation>
				Responsável técnico: {technicalManager}
			</S.FooterInformation>

			<S.Copyright>
				&copy; {new Date().getFullYear()} Fleury - Todos os Direitos Reservados
				|{' '}
				<Link
					to="https://dpo.privacytools.com.br/policy-view/AynyEq4GV/1/poli%CC%81tica-de-privacidade/pt_BR?s=1670867684492"
					// to="https://cdn.cosmicjs.com/86e20a20-ff09-11e9-92d1-516d3eb9feef-Poltica-de-Privacidade-e-Publicidade.pdf"
					label="Política de Privacidade e Publicidade"
					external
					light
				/>{' '}
				|{' '}
				<Link
					to="https://cdn.cosmicjs.com/77549050-ff09-11e9-92d1-516d3eb9feef-Poltica-de-Autoridade.pdf"
					label="Política de
								Autoridade"
					external
					light
				/>{' '}
				|{' '}
				<Link
					to="https://cdn.cosmicjs.com/65feb290-ff09-11e9-92d1-516d3eb9feef-Fonte-de-Financiamento.pdf"
					label="Fonte de Financiamento"
					external
					light
				/>{' '}
				| Atualizado em 06/06/2023
			</S.Copyright>
		</S.Container>
	);
};

export default Footer;
