import styled, { css } from 'styled-components';
import { media, spacing, toRem } from '@styles/Theme';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	height: 100vh;

	> * {
		width: 100%;
	}
`;

export const Section = styled.section`
	padding: ${spacing(5, 0)};
	overflow: hidden;

	@media (${media.tablet}) {
		padding: ${spacing(7, 0)};
	}

	@media (${media.desktop}) {
		padding: ${spacing(10, 0)};
	}

	background: ${({ bgColor }) => bgColor || 'transparent'};

	${({ bgImg }) =>
		bgImg &&
		css`
			background-repeat: no-repeat;
			background-size: cover;
			background-image: url(${bgImg});
		`};
`;

export const Wrapper = styled.div`
	max-width: ${toRem(1216)};
	margin: 0 auto;
	padding: ${spacing(0, 2)};

	@media (${media.tablet}) {
		padding: ${spacing(0, 3)};
	}
`;
