import styled, { css, keyframes } from 'styled-components';
import { Link } from 'gatsby';

import { colors, shadow, spacing, toRem, zIndex } from '@styles/Theme';

const spin = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

export const Container = styled.div`
	position: absolute;
	z-index: ${zIndex.max};
	width: 100%;
	background-color: #fff;
	border-radius: ${spacing(2)};
	box-shadow: ${shadow};

	${({ open }) =>
		!open &&
		css`
			visibility: hidden;
		`}
`;

export const Item = styled(Link)`
	display: block;
	padding: ${spacing(2)};
	cursor: pointer;
	transition: background-color 0.2s linear;

	&:last-child {
		border-radius: ${spacing(0, 0, 2, 2)};
	}

	&:hover {
		background-color: #f9f9f9;
	}

	small {
		display: block;
		color: ${colors.darkGrey};
		font-size: ${toRem(14)};
	}
`;

export const Loading = styled.span`
	display: flex;
	align-items: center;
	padding: ${spacing(2)};

	svg {
		width: ${spacing(2)};
		height: ${spacing(2)};
		margin-right: ${spacing(1)};
		animation: ${spin} 0.8s infinite linear;
	}
`;

export const NotFound = styled.span`
	display: block;
	padding: ${spacing(2)};

	b {
		color: ${colors.primary};
		font-weight: bold;
	}
`;
