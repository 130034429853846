module.exports.BUCKET_SLUG = 'infusoes';
module.exports.READ_KEY = 'xEldeA5zqrqe7orDTn8OjSXr3FAOprMZzHfL3l3uHT9odnOKLS';
module.exports.OBJECT_TYPES = [
	'about',
	'call-to-actions',
	'clinic-conditions',
	'contacts',
	'drugs',
	'faq',
	'homepage',
	'insurances',
	'meta',
	'mobile-services',
	'modals',
	'pages',
	'post-categories',
	'posts',
	'sidebars',
	'units',
	'landing-pages',
];

module.exports.ELASTIC_URL = `https://search-search-search-sites-hml-ubji4vzomaybbeabz36ydr6soy.us-west-2.es.amazonaws.com`;
module.exports.API_URL = `https://api.grupofleury.com.br`;
module.exports.DEV_API_URL = `https://api.grupofleury.com.br/notificacao/corporativo/v1`;
module.exports.AUTH = {
  client_id: `baf302c6-5260-317b-a516-20c60175547b`,
  // redirect_uri: `https://api-hml.grupofleury.com.br`,
  grant_type: `authorization_code`,
};
