export const INITIAL_STATE = {
	isNavOpened: false,
	isModalOpened: false,
	contacts: {},
	showAppointmentButton: true,
};

export const TOGGLE_NAV = `TOGGLE_NAV`;
export const CLOSE_NAV = `CLOSE_NAV`;
export const TOGGLE_MODAL = `TOGGLE_MODAL`;
export const SET_CONTACTS = `SET_CONTACTS`;

export const HIDE_APPOINTMENT_BUTTON = 'HIDE_APPOINTMENT_BUTTON';
export const SHOW_APPOINTMENT_BUTTON = 'SHOW_APPOINTMENT_BUTTON';
