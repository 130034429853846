import styled from 'styled-components';
import { shade } from 'polished';

import { colors, fontSize, media, spacing, toRem } from '@styles/Theme';
import { H4 } from '@styles/Typography';

import { Wrapper as LWrapper } from '@components/Layout/styles';

import logoLarge from '@images/logo-footer.svg';

export const ToTop = styled.button`
	position: absolute;
	top: 0;
	left: 50%;
	display: flex;
	align-items: center;
	padding: ${spacing(1.5, 1)};
	color: white;
	font-weight: bold;
	font-size: ${fontSize.small};
	letter-spacing: ${toRem(1)};
	background-color: ${colors.darkGrey};
	border: none;
	border-radius: ${toRem(0, 0, 8, 8)};
	outline: none;
	transform: translateX(-50%);
	transition: background-color 0.2s linear;

	&:hover {
		background-color: ${shade(0.2, colors.darkGrey)};
	}
`;

export const Container = styled.footer`
	position: relative;
	margin-top: auto;
	padding: ${spacing(12, 0, 5)};
	color: white;
	background-color: ${colors.grey};
`;

export const Content = styled(LWrapper)`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: ${fontSize.small};

	@media (${media.desktop}) {
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;
	}
`;

export const LogoContainer = styled.div`
	margin-bottom: ${spacing(5)};

	@media (${media.tablet}) {
		margin-bottom: ${spacing(11)};
	}
`;

export const Logo = styled.div`
	width: ${toRem(227)};
	height: ${toRem(32)};
	margin-bottom: ${spacing(4)};
	background: url(${logoLarge}) no-repeat;
	background-size: contain;
	filter: brightness(10);
`;

export const MenuContainer = styled.div`
	margin-left: ${spacing(-8)};
	letter-spacing: ${toRem(1)};

	@media (${media.tablet}) {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	@media (${media.desktop}) {
		flex: 1;
		justify-content: flex-start;
		width: auto;
		margin-left: ${spacing(15)};
	}
`;

export const MenuColum = styled.div`
	margin-bottom: ${spacing(5)};

	@media (${media.tablet}) {
		width: calc(100% / 3 - ${spacing(8)});
		margin-bottom: ${spacing(11)};
	}

	@media (${media.desktop}) {
		margin-bottom: ${spacing(15)};
	}
`;

export const MenuTitle = styled(H4)`
	color: white;
	font-size: ${fontSize.small};

	@media (${media.tablet}) {
		font-size: ${fontSize.small};
	}
`;

export const MenuContent = styled.ul``;

export const MenuItem = styled.li`
	display: flex;
	align-items: center;
	font-weight: bold;

	& + & {
		margin-top: ${spacing(1)};
	}

	svg {
		margin-right: ${spacing(1)};
	}
`;

export const Copyright = styled.p`
	margin-top: 16px;
	font-weight: bold;
	text-align: center;
	font-size: ${fontSize.small};

	@media (${media.desktop}) {
		width: 100%;
	}
`;

export const FooterInformation = styled.p`
	text-align: center;
	font-weight: 300;
	font-size: ${fontSize.small};
`;
