import {
	FacebookSquare,
	Instagram,
	LinkedinSquare,
	Twitter,
	Whatsapp,
	Youtube,
} from '@styled-icons/boxicons-logos';

import {
	Error,
	Map,
	MessageRoundedDetail,
	Phone,
} from '@styled-icons/boxicons-solid';

import {
	ChevronLeft,
	ChevronRight,
	ChevronUp,
	LoaderAlt,
	MinusCircle,
	PlusCircle,
	RightArrowCircle,
	Search,
	Send,
	X,
	File,
} from '@styled-icons/boxicons-regular';

export const ChatIcon = MessageRoundedDetail;
export const ChevronLeftIcon = ChevronLeft;
export const ChevronRightIcon = ChevronRight;
export const ChevronUpIcon = ChevronUp;
export const CloseIcon = X;
export const ErrorIcon = Error;
export const FacebookIcon = FacebookSquare;
export const InstagramIcon = Instagram;
export const LinkedinIcon = LinkedinSquare;
export const LoaderIcon = LoaderAlt;
export const MapIcon = Map;
export const MinusIcon = MinusCircle;
export const PhoneIcon = Phone;
export const PlusIcon = PlusCircle;
export const RightArrowCircleIcon = RightArrowCircle;
export const SearchIcon = Search;
export const SendIcon = Send;
export const TwitterIcon = Twitter;
export const WhatsappIcon = Whatsapp;
export const YoutubeIcon = Youtube;
export const FileIcon = File;
