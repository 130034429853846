import styled, { css } from 'styled-components';
import { colors, spacing, toRem } from '@styles/Theme';

const modelChooser = (model, isFilled, isFocused) => {
	if (model !== 'material' && (isFilled || isFocused)) {
		return css`
			opacity: 0;
		`;
	}

	if (model === 'material' && (isFilled || isFocused)) {
		return css`
			font-size: ${toRem(12)};
			transform: translateY(${spacing(-3)});
		`;
	}

	return false;
};

export const Container = styled.label`
	position: relative;
	display: flex;
	justify-content: center;
	padding: ${spacing(1)};
	border: none;
	border-bottom: ${toRem(1)} solid ${colors.lightGrey};
	user-select: none;

	input,
	textarea {
		position: relative;
		display: block;
		width: 100%;
		background-color: transparent;
		border: none;
		resize: vertical;
	}

	textarea {
		height: 100%;
	}

	${({ model }) =>
		model === 'material' &&
		css`
			padding-top: ${spacing(4)};
		`}

	${({ fieldType }) =>
		fieldType === 'textarea' &&
		css`
			justify-content: flex-start;
			min-height: ${spacing(12)};
		`}

	${({ isFocused }) =>
		isFocused &&
		css`
			border-color: ${colors.primary};
		`};
`;

export const Placeholder = styled.span`
	position: absolute;
	left: ${spacing(1)};
	transition: all 0.08s linear;

	${({ prefix }) =>
		prefix &&
		css`
			left: ${spacing(5)};
		`}

	${({ model, isFilled, isFocused, prefix }) =>
		modelChooser(model, isFilled, isFocused, prefix)};

	${({ isFocused }) =>
		isFocused &&
		css`
			color: ${colors.primary};
		`}
`;

export const PrefixIcon = styled.span`
	display: block;
	width: ${spacing(3)};
	height: ${spacing(3)};
	margin-right: ${spacing(1)};
	color: ${colors.grey};
`;

export const Error = styled.small`
	padding-left: ${spacing(1)};
	color: red;
	font-size: ${toRem(12)};
`;
