import styled, { css, keyframes } from 'styled-components';
import { Link } from 'gatsby';

import { colors, fontSize, media, spacing, toRem } from '@styles/Theme';

const spin = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

const isInverted = (mode, yes, no) => (mode === 'inverted' ? yes : no);

const outlinedStyle = mode => css`
	color: ${isInverted(mode, 'white', colors.primary)};
	background: transparent;
	border-color: ${isInverted(mode, 'white', colors.primary)};

	&:hover {
		color: ${isInverted(mode, colors.primary, 'white')};
		background-color: ${isInverted(mode, 'white', colors.primary)};
	}
`;

const filledStyle = mode => css`
	color: ${isInverted(mode, colors.primary, 'white')};
	background: ${isInverted(mode, 'white', colors.primary)};

	&:hover {
		color: ${isInverted(mode, colors.primary, 'white')};
		box-shadow: 0 0 10px
			rgba(${isInverted(mode, '255, 255, 255', '228, 11, 75')}, 0.7);
	}
`;

const basicStyle = css`
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	font-size: ${fontSize.small};
	text-transform: capitalize;
	text-decoration: none;
	border: ${toRem(1)} solid transparent;
	border-radius: ${toRem(48)};
	outline: none;
	cursor: pointer;
	transition-timing-function: linear;
	transition-duration: 0.1s;
	transition-property: color, background-color, box-shadow, border;
	user-select: none;

	${({ outlined, inverted }) =>
		outlined ? outlinedStyle(inverted) : filledStyle(inverted)};

	@media (${media.mobileAndTablet}) {
		${({ small }) =>
			small
				? css`
						width: ${toRem(114)};
						height: ${spacing(3)};
						padding: ${(0, 24)};
				  `
				: css`
						width: ${toRem(232)};
						height: ${spacing(6)};
						padding: ${toRem(0, 40)};
				  `}
	}

	@media (${media.desktop}) {
		width: fit-content;
		height: ${spacing(6)};
		padding: ${toRem(16, 40)};
	}
`;

export const InternalButton = styled(Link)`
	${basicStyle}
`;

export const ExternalButton = styled.a`
	${basicStyle}
`;

export const Button = styled.button`
	${basicStyle}

	svg {
		width: ${spacing(2)};
		height: ${spacing(2)};
		margin-right: ${spacing(1)};
		animation: ${spin} 0.8s infinite linear;
	}

	&:disabled {
		color: #fff;
		background-color: ${colors.lightGrey};

		&:hover {
			box-shadow: none;
			cursor: default;
		}
	}
`;

export const ButtonGroup = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	button:not(:first-child),
	a:not(:first-child) {
		margin-left: ${spacing(2)};
	}

	@media (${media.desktop}) {
		a:not(:first-child) {
			margin-left: ${spacing(4)};
		}
	}
`;
