import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { colors, fontSize, media, spacing, toRem } from '@styles/Theme';

import logoSmallImg from '@images/logo-fleury-small.svg';

import { Wrapper as LWrapper } from '@components/Layout/styles';

export const Container = styled.nav`
	transform: translateX(-100%);

	@media (${media.mobileAndTablet}) {
		position: fixed;
		top: 0;
		width: 100vw;
		height: 100vh;
		background: ${colors.gradient(150)};

		${({ isOpen }) =>
			isOpen &&
			css`
				transform: translateX(0%);
			`}
	}

	@media (${media.desktop}) {
		background: ${colors.gradient(150)};
		transform: translateX(0%);
	}
`;

export const MenuHeader = styled.div`
	display: flex;
	flex-direction: column;

	@media (${media.tablet}) {
		flex-direction: row;
		justify-content: space-between;
		margin-right: ${spacing(3)};
	}

	@media (${media.desktop}) {
		display: none;
	}
`;

export const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	height: ${spacing(9)};
	padding-left: ${spacing(8)};
	border-bottom: ${toRem(1)} solid #d30000;

	path {
		color: white;
	}

	@media (${media.mobileOnly}) {
		margin-bottom: ${spacing(4)};
	}

	@media (${media.tablet}) {
		border-bottom: none;
	}
`;

export const Logo = styled(Link)`
	display: block;
	width: ${toRem(110)};
	height: ${toRem(24)};
	background-image: url(${logoSmallImg});
	background-repeat: no-repeat;
	background-size: contain;
	filter: brightness(10);
`;

export const Menu = styled(LWrapper)`
	padding-left: ${spacing(8)};
	color: white;

	@media (${media.desktop}) {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: ${toRem(36)};
		padding-left: ${spacing(3)};
	}
`;

export const MenuItem = styled.li`
	font-size: ${fontSize.small};
	letter-spacing: ${toRem(1)};

	&:not(:last-child) {
		margin-right: ${spacing(3)};
	}

	a {
		color: white;
		font-weight: bold;
		text-decoration: none;
		transition: color 0.2s linear;

		&:hover,
		.active {
			color: ${colors.lightGrey};
		}
	}

	@media (${media.mobileAndTablet}) {
		margin-top: ${spacing(4)};
	}
`;
