import React from 'react';
import PropTypes from 'prop-types';
import { Reset } from 'styled-reset';

import { GlobalProvider } from '@context/global';

import './static/fonts/fonts.css';
import GlobalStyle from '@styles/GlobalStyle';

import Layout from '@components/Layout';

export const wrapRootElement = ({ element }) => (
	<GlobalProvider>
		<Reset />
		<GlobalStyle />
		<Layout>{element}</Layout>
	</GlobalProvider>
);

wrapRootElement.propTypes = {
	element: PropTypes.func.isRequired,
};
