import styled from 'styled-components';

import { colors, media, toRem, zIndex } from '@styles/Theme';

export const Container = styled.div`
	position: fixed;
	top: ${toRem(28)};
	left: ${toRem(16)};
	z-index: ${zIndex.max};
	width: ${toRem(24)};
	height: ${toRem(15)};
	transform: rotate(0deg);
	cursor: pointer;
	transition: 0.5s ease-in-out;

	span {
		position: absolute;
		left: 0;
		display: block;
		width: 100%;
		height: ${toRem(3)};
		background: ${colors.gradient(360)};
		border-radius: ${toRem(3)};
		transform: rotate(0deg);
		opacity: 1;
		transition: 0.25s ease-in-out;
	}

	span:nth-child(1) {
		top: 0;
	}

	span:nth-child(2),
	span:nth-child(3) {
		top: ${toRem(6)};
	}

	span:nth-child(4) {
		top: ${toRem(12)};
	}

	&.open span {
		background: white;
	}

	&.open span:nth-child(1) {
		top: ${toRem(6)};
		left: 50%;
		width: 0%;
	}

	&.open span:nth-child(2) {
		transform: rotate(45deg);
	}

	&.open span:nth-child(3) {
		transform: rotate(-45deg);
	}

	&.open span:nth-child(4) {
		top: ${toRem(6)};
		left: 50%;
		width: 0%;
	}

	@media (${media.tablet}) {
		left: ${toRem(24)};
	}

	@media (${media.desktop}) {
		display: none;
	}
`;
