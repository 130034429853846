import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { colors, media, spacing } from '@styles/Theme';

export const H1 = styled.div`
	margin-bottom: ${spacing(4)};
	color: ${colors.darkGrey};
	font-weight: bold;
	font-size: 4rem;
	line-height: 4rem;

	@media (${media.tablet}) {
		margin-bottom: ${spacing(5)};
		font-size: 4.4rem;
		line-height: 4.4rem;
	}

	@media (${media.desktop}) {
		margin-bottom: ${spacing(8)};
		font-size: 5.2rem;
		line-height: 5.2rem;
	}
`;

export const H2 = styled.div`
	margin-bottom: ${spacing(3)};
	color: ${colors.darkGrey};
	font-weight: bold;
	font-size: 2.8rem;
	line-height: 3.2rem;

	@media (${media.tablet}) {
		font-size: 3.2rem;
		line-height: 3.2rem;
	}

	@media (${media.desktop}) {
		font-size: 4.4rem;
		line-height: 5.6rem;
	}
`;

export const H3 = styled.div`
	margin-bottom: ${spacing(2)};
	color: ${colors.darkGrey};
	font-weight: bold;
	font-size: 2rem;
	line-height: 2.8rem;

	@media (${media.tablet}) {
		margin-bottom: ${spacing(3)};
		font-size: 2.8rem;
		line-height: 3.6rem;
	}

	@media (${media.desktop}) {
		margin-bottom: ${spacing(3)};
		font-size: 3.2rem;
		line-height: 4rem;
	}
`;

export const H4 = styled.div`
	margin-bottom: ${spacing(2)};
	color: ${colors.darkGrey};
	font-weight: bold;
	font-size: 1.8rem;
	line-height: 1.8rem;

	@media (${media.tablet}) {
		font-size: 2rem;
		line-height: 2rem;
	}
`;

export const H5 = styled.div`
	margin-bottom: ${spacing(3)};
	color: ${colors.darkGrey};
	font-weight: bold;
	font-size: 1.6rem;
	line-height: 2.4rem;

	@media (${media.desktop}) {
		font-size: 2rem;
	}
`;

export const H6 = styled.div`
	margin-bottom: ${spacing(2)};
	color: ${colors.darkGrey};
	font-weight: bold;
	font-size: 1.4rem;
	line-height: 1.4rem;

	@media (${media.desktop}) {
		font-size: 1.6rem;
		line-height: 1.6rem;
	}
`;

export const P = styled.div`
	color: ${colors.grey};
	font-size: 1.6rem;
	line-height: 1.5;

	${({ small }) =>
		small &&
		css`
			font-size: 1.4rem;
		`}
`;

H1.propTypes = { as: PropTypes.string };
H2.propTypes = { as: PropTypes.string };
H3.propTypes = { as: PropTypes.string };
H4.propTypes = { as: PropTypes.string };
H5.propTypes = { as: PropTypes.string };
H6.propTypes = { as: PropTypes.string };
P.propTypes = { as: PropTypes.string };

H1.defaultProps = { as: 'h1' };
H2.defaultProps = { as: 'h2' };
H3.defaultProps = { as: 'h3' };
H4.defaultProps = { as: 'h4' };
H5.defaultProps = { as: 'h5' };
H6.defaultProps = { as: 'h6' };
P.defaultProps = { as: 'p' };
