import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import useGlobal from '@hooks/useGlobal';
import { CLOSE_NAV, TOGGLE_MODAL } from '@reducers/actions';

import Button from '@components/Button';

const AppointmentButton = ({ children, ...rest }) => {
	const [, dispatch] = useGlobal();

	const handleClick = useCallback(
		e => {
			e.preventDefault();
			dispatch({ type: CLOSE_NAV });
			dispatch({ type: TOGGLE_MODAL });
		},
		[dispatch]
	);

	return (
		<Button {...rest} onClick={handleClick}>
			{children || 'Agendar'}
		</Button>
	);
};

AppointmentButton.propTypes = {
	children: PropTypes.node,
};

AppointmentButton.defaultProps = {
	children: undefined,
};

export default AppointmentButton;
