import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { LoaderIcon } from '@components/Icons';

import * as S from './styles';

const Button = ({
	children,
	hidden,
	inverted,
	loading,
	outlined,
	small,
	to,
	type,
	...rest
}) => {
	const internal = /^\/(?!\/)/.test(to);

	const propsObj = useMemo(
		() => ({
			hidden,
			inverted: inverted ? 'inverted' : undefined,
			outlined: outlined ? 'outlined' : undefined,
			small: small ? 'small' : undefined,
			target: '_blank',
			to,
		}),
		[hidden, inverted, outlined, small, to]
	);

	if (type) {
		return (
			<S.Button
				type={type}
				{...rest}
				disabled={loading}
				outlined={propsObj.outlined}
				inverted={propsObj.inverted}
				small={propsObj.small}
			>
				{loading && <LoaderIcon />} {children}
			</S.Button>
		);
	}

	return (
		<>
			{internal ? (
				<S.InternalButton
					to={to}
					outlined={propsObj.outlined}
					inverted={propsObj.inverted}
					small={propsObj.small}
					hidden={propsObj.hidden}
					{...rest}
				>
					{children}
				</S.InternalButton>
			) : (
				<S.ExternalButton
					href={propsObj.to}
					outlined={propsObj.outlined}
					inverted={propsObj.inverted}
					small={propsObj.small}
					hidden={propsObj.hidden}
					{...rest}
					target="_blank"
				>
					{children}
				</S.ExternalButton>
			)}
		</>
	);
};

Button.propTypes = {
	children: PropTypes.node.isRequired,
	hidden: PropTypes.string,
	inverted: PropTypes.bool,
	loading: PropTypes.bool,
	outlined: PropTypes.bool,
	small: PropTypes.bool,
	to: PropTypes.string,
	type: PropTypes.string,
};

Button.defaultProps = {
	inverted: false,
	hidden: '',
	loading: false,
	outlined: false,
	small: false,
	to: '#',
	type: null,
};

export const ButtonGroup = ({ children }) => (
	<S.ButtonGroup>{children}</S.ButtonGroup>
);

ButtonGroup.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Button;
