import { useContext } from 'react';

import { GlobalContext } from '@context/global';

const useGlobal = () => {
	const contextValue = useContext(GlobalContext);
	return contextValue;
};

export default useGlobal;
