export const base = 8;

export const toRem = (...valuesInPx) =>
	valuesInPx
		.map(value => (Number(value) ? `${value / 10}rem` : value))
		.join(` `);

export const spacing = (...spaceSize) =>
	spaceSize
		.map(space =>
			Number(space) || space === 0 ? `${(space * base) / 10}rem` : space
		)
		.join(` `);

export const colors = {
	primary: '#e40b4b',
	secondary: '#e74f0f',
	darkGrey: '#464646',
	grey: '#77787b',
	lightGrey: '#bfbfbf',
	gradient: deg => `
		transparent linear-gradient(${deg}deg, 
			#e40b4b 0%, 
			#e40b4b 60%, 
			#e74f0f 100%
		)`,
};

export const media = {
	mobileOnly: 'max-width: 767px',
	mobileAndTablet: 'max-width: 1023px',
	tablet: 'min-width: 768px',
	desktop: 'min-width: 1024px',
};

export const shadow = `0 4px 10px rgba(0, 0, 0, 0.16)`;

export const boxShadow = `0px 0px 80px #7c7c7c29`;

export const zIndex = {
	normal: 1,
	floated: 10,
	medium: 20,
	max: 100,
};

export const fontSize = {
	regular: toRem(16),
	small: toRem(14),
};
