import React, { createContext, useReducer } from 'react';

import PropTypes from 'prop-types';

import { reducer } from '@reducers/global';
import { INITIAL_STATE } from '@reducers/actions';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
	const contextValue = useReducer(reducer, INITIAL_STATE);

	return (
		<GlobalContext.Provider value={contextValue}>
			{children}
		</GlobalContext.Provider>
	);
};

GlobalProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
