import { createGlobalStyle } from 'styled-components';
import { shade } from 'polished';

import { colors, fontSize, media, spacing, toRem } from '@styles/Theme';

export default createGlobalStyle`
	*,
	*::after,
	*::before{
		box-sizing: border-box;
		outline: none;
	}

	html {
		font-size: 10px;
	}

	html, body {
		height: 100vh;
	}

	body, input, button, textarea {
		font: normal ${
			fontSize.regular
		}/1.5 'Signika', -apple-system, system-ui, sans-serif;
		-webkit-font-smoothing: antialiased;
	}

	body {
		padding-top: ${spacing(9)};
		color: ${colors.grey};
		background-color: #fafafa;

		@media(${media.desktop}) {
			padding-top: ${toRem(108)};
		}
	}

	button {
		cursor: pointer;
	}

	strong, b {
		font-weight: bold;
	}

	ul[class] {
		list-style: none;
	}

	a {
		color: ${colors.primary};
		text-decoration: none;
	}

	a:hover {
		color: ${shade(0.2, colors.primary)}
	}

	@media (${media.mobileOnly}) {
		.hide-mobile {
			display: none;
		}
	}
`;
