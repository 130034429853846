import styled, { css } from 'styled-components';

import { colors, media, spacing, toRem, zIndex } from '@styles/Theme';
import { P } from '@styles/Typography';

import {
	ChatIcon,
	CloseIcon,
	PhoneIcon,
	WhatsappIcon,
} from '@components/Icons';

const iconStyle = css`
	position: absolute;
	top: ${spacing(-1)};
	left: ${spacing(-6)};
	width: ${spacing(4)};
	color: ${colors.primary};

	@media (${media.tablet}) {
		left: ${spacing(-10)};
		top: ${spacing(-0.7)};
		width: ${spacing(4)};
	}
`;

export const Close = styled(CloseIcon)`
	position: absolute;
	top: ${spacing(1)};
	right: ${spacing(1)};
	width: ${spacing(4)};
	color: ${colors.darkGrey};
	cursor: pointer;

	@media (${media.tablet}) {
		top: ${spacing(2)};
		right: ${spacing(2)};
		width: ${spacing(4)};
	}
`;

export const Overlay = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: ${zIndex.max};

	@media (${media.mobileOnly}) {
		background-color: #fff;
	}

	@media (${media.tablet}) {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.3);
	}

	@media (${media.mobileOnly}) and (orientation: landscape) {
		overflow: scroll;
	}
`;

export const Modal = styled.div`
	position: relative;
	flex: 1;
	max-width: ${toRem(420)};
	padding: ${spacing(3)};
	background-color: #fff;
	border-radius: 8px;
	animation: 2s linear topDownModal;

	@media (${media.desktop}) {
		max-width: ${toRem(430)};
	}
`;

export const Header = styled.div`
	margin-bottom: ${spacing(3)};
`;

export const Contacts = styled.div`
	padding-left: ${spacing(6)};
	text-align: left;

	&:not(:first-child) {
		margin-top: ${spacing(4)};
	}

	@media (${media.tablet}) {
		display: flex;
		flex-wrap: wrap;
		padding-left: ${spacing(10)};

		&:not(:first-child) {
			margin-top: ${spacing(2)};
			padding-top: ${spacing(1)};
		}
	}
`;

export const Contact = styled.div`
	position: relative;

	@media (${media.tablet}) {
		&:not(:first-child) {
			margin-left: ${spacing(7)};
			padding-left: ${spacing(7)};
			border-left: ${toRem(1)} solid ${colors.lightGrey};
		}
	}
`;

export const Phone = styled(PhoneIcon)`
	${iconStyle}
`;

export const Whats = styled(WhatsappIcon)`
	${iconStyle}
`;

export const Chat = styled(ChatIcon)`
	${iconStyle}
`;

export const PhoneContent = styled.a`
	display: block;
	text-decoration: none;

	@media (${media.tablet}) {
		&[href^='tel'] {
			cursor: default;
			pointer-events: none;
		}
	}

	@media (${media.mobileOnly}) {
		cursor: pointer;
		pointer-events: auto;
	}
`;

export const Number = styled.div`
	color: ${colors.primary};
	font-style: normal;
	font-weight: 800;
	font-size: 14px;
	line-height: 24px;

	@media (${media.tablet}) {
		margin-left: -24px;
	}
`;

export const HourPhone = styled(P)`
	width: 100%;
`;

export const Separator = styled.div`
	padding-top: 16px;
`;

export const AttendanceHours = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-align: flex-start;
	background-color: #e5f3f1;
	border-radius: 6px;
	color: #444444;
	padding: 12px;
	margin-top: 12px;

	ul {
		padding-left: 12px !important;
		margin-top: 4px !important;
		margin-bottom: -2px !important;
		list-style: inside;
	}
`;
