// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-atendimento-movel-index-js": () => import("./../src/pages/atendimento-movel/index.js" /* webpackChunkName: "component---src-pages-atendimento-movel-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-como-funciona-index-js": () => import("./../src/pages/como-funciona/index.js" /* webpackChunkName: "component---src-pages-como-funciona-index-js" */),
  "component---src-pages-condicoes-clinicas-index-js": () => import("./../src/pages/condicoes-clinicas/index.js" /* webpackChunkName: "component---src-pages-condicoes-clinicas-index-js" */),
  "component---src-pages-contato-index-js": () => import("./../src/pages/contato/index.js" /* webpackChunkName: "component---src-pages-contato-index-js" */),
  "component---src-pages-convenios-index-js": () => import("./../src/pages/convenios/index.js" /* webpackChunkName: "component---src-pages-convenios-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-medicamentos-index-js": () => import("./../src/pages/medicamentos/index.js" /* webpackChunkName: "component---src-pages-medicamentos-index-js" */),
  "component---src-pages-perguntas-frequentes-index-js": () => import("./../src/pages/perguntas-frequentes/index.js" /* webpackChunkName: "component---src-pages-perguntas-frequentes-index-js" */),
  "component---src-pages-portal-de-privacidade-index-js": () => import("./../src/pages/portal-de-privacidade/index.js" /* webpackChunkName: "component---src-pages-portal-de-privacidade-index-js" */),
  "component---src-pages-unidades-index-js": () => import("./../src/pages/unidades/index.js" /* webpackChunkName: "component---src-pages-unidades-index-js" */),
  "component---src-templates-blog-post-index-js": () => import("./../src/templates/blog-post/index.js" /* webpackChunkName: "component---src-templates-blog-post-index-js" */),
  "component---src-templates-clinic-conditions-index-js": () => import("./../src/templates/clinic-conditions/index.js" /* webpackChunkName: "component---src-templates-clinic-conditions-index-js" */),
  "component---src-templates-faq-index-js": () => import("./../src/templates/faq/index.js" /* webpackChunkName: "component---src-templates-faq-index-js" */)
}

