import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import useGlobal from '@hooks/useGlobal';
import { SET_CONTACTS } from '@reducers/actions';

import Header from '@components/Header';
import Footer from '@components/Footer';
import AppointmentModal from '@components/AppointmentModal';

import * as S from './styles';

const Layout = ({ children }) => {
	const [state, dispatch] = useGlobal();

	const { allCosmicjsContacts } = useStaticQuery(graphql`
		{
			allCosmicjsContacts {
				edges {
					node {
						metadata {
							show
							link
							label
							hour
						}
						slug
					}
				}
			}
		}
	`);

	useEffect(() => {
		const contacts = allCosmicjsContacts.edges
			.filter(contact => contact.node.metadata.show)
			.reduce((acc, next) => {
				Object.assign(acc, { [next.node.slug]: next.node.metadata });

				return acc;
			}, {});

		dispatch({ type: SET_CONTACTS, payload: contacts });
	}, [allCosmicjsContacts, dispatch]);

	return (
		<>
			<Helmet
				bodyAttributes={{
					style: `overflow: ${
						state.isNavOpened || state.isModalOpened ? 'hidden' : 'unset'
					}`,
				}}
			/>

			<S.Container>
				<Header showAppointmentButton={state.showAppointmentButton} />
				{children}
				<Footer />
			</S.Container>
			{state.isModalOpened && <AppointmentModal />}
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
