import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SearchIcon } from '@components/Icons';

import Nav from '@components/Nav';
import Button, { ButtonGroup } from '@components/Button';
import AppointmentButton from '@components/Button/AppointmentButton';
import TextField from '@components/TextField';
import InputSearchList from '@components/InputSearchList';

import { search } from '@services/elastic';

import { useDebounce } from 'use-debounce';

import * as S from './styles';

const query = value => ({
	query: {
		bool: {
			must: {
				query_string: {
					query: `*${value}*`,
				},
			},
		},
	},
	highlight: {
		fields: {
			title: {},
		},
		pre_tags: ['<strong>'],
		post_tags: ['</strong>'],
		boundary_scanner_locale: 'pt-BR',
	},
	from: 0,
	size: 5,
});

const Header = ({ showAppointmentButton }) => {
	const [result, setResult] = useState([]);
	const [term, setTerm] = useState('');
	const [loading, setLoading] = useState(false);
	const [notFound, setNotFound] = useState(false);

	const [termDebounced] = useDebounce(term, 500);

	const handleSearch = useCallback(async ({ target: { value } }) => {
		setTerm(value);
	}, []);

	useEffect(() => {
		const fetchSearch = async () => {
			if (termDebounced) {
				setLoading(true);
				try {
					const {
						data: {
							hits: { hits },
						},
					} = await search({ query: query(termDebounced) });

					setResult(hits);
					setNotFound(false);

					if (!hits.length) {
						setNotFound(true);
					}
				} catch (error) {
					console.log(error); // eslint-disable-line
				} finally {
					setLoading(false);
				}
			}
		};

		fetchSearch();
	}, [termDebounced]);

	return (
		<S.Container>
			<S.HeaderContainer>
				<S.LogoContainer>
					<S.Logo to="/" />
				</S.LogoContainer>

				<S.SearchInput>
					<TextField
						type="text"
						placeholder="O que você procura?"
						name="search"
						prefix={<SearchIcon />}
						onChange={handleSearch}
						autoComplete="off"
						value={term}
					/>

					<InputSearchList
						result={result}
						term={termDebounced}
						loading={loading}
						notFound={notFound}
					/>
				</S.SearchInput>

				<ButtonGroup>
					<Button
						to="https://resultados.fleury.com.br/Cliente/"
						className="hide-mobile"
						outlined
						small
					>
						Resultados
					</Button>
					{showAppointmentButton && <AppointmentButton small />}
				</ButtonGroup>
			</S.HeaderContainer>
			<Nav />
		</S.Container>
	);
};

Header.propTypes = {
	showAppointmentButton: PropTypes.bool.isRequired,
};

export default Header;
