import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const TextField = ({
	children,
	className,
	error,
	fieldType,
	model,
	name,
	placeholder,
	prefix,
	register,
	value,
	...rest
}) => {
	const [isFocused, setIsFocused] = useState(false);

	const handleBlur = useCallback(() => {
		setIsFocused(false);
	}, []);

	const handleFocus = useCallback(() => {
		setIsFocused(true);
	}, [setIsFocused]);

	return (
		<div>
			<S.Container
				className={className}
				fieldType={fieldType}
				htmlFor={name}
				isFocused={isFocused}
				model={model}
				prefix={prefix ? 'true' : null}
			>
				{prefix && <S.PrefixIcon>{prefix}</S.PrefixIcon>}

				<S.Placeholder
					isFilled={!!value}
					isFocused={isFocused}
					model={model}
					prefix={prefix ? 'true' : null}
				>
					{placeholder}
				</S.Placeholder>

				{fieldType === 'textarea' ? (
					<textarea
						id={name}
						name={name}
						onBlur={handleBlur}
						onFocus={handleFocus}
						ref={register}
						{...rest}
					/>
				) : (
					<input
						id={name}
						name={name}
						onBlur={handleBlur}
						onFocus={handleFocus}
						ref={register}
						{...rest}
					/>
				)}
				{children && children}
			</S.Container>

			{error && <S.Error>{error.message}</S.Error>}
		</div>
	);
};

TextField.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	error: PropTypes.objectOf(PropTypes.string),
	fieldType: PropTypes.string,
	model: PropTypes.string,
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	prefix: PropTypes.node,
	register: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
	value: PropTypes.string.isRequired,
};

TextField.defaultProps = {
	children: undefined,
	className: undefined,
	error: undefined,
	fieldType: 'input',
	model: 'normal',
	prefix: null,
	register: undefined,
};

export default TextField;
