import React, { useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import useGlobal from '@hooks/useGlobal';
import { TOGGLE_MODAL } from '@reducers/actions';

import { H3, P } from '@styles/Typography';

import * as S from './styles';

const Modal = () => {
	const [state, dispatch] = useGlobal();
	const {
		contacts: { phone, whatsapp },
	} = state;

	const handleClose = useCallback(() => dispatch({ type: TOGGLE_MODAL }), [
		dispatch,
	]);

	const { allCosmicjsModals } = useStaticQuery(graphql`
		{
			allCosmicjsModals {
				nodes {
					metadata {
						description
						title
						schedule
					}
				}
			}
		}
	`);

	const { description, title, schedule } = allCosmicjsModals.nodes[0].metadata;

	return (
		<S.Overlay>
			<S.Modal>
				<S.Close onClick={handleClose} />
				<S.Header>
					<H3>{title}</H3>
					<S.Separator />
					<P>{description}</P>
				</S.Header>

				<div>
					{phone && (
						<S.Contacts>
							<S.Contact>
								<S.Phone />
								<S.PhoneContent href={phone.link}>
									<S.Number>{phone.label}</S.Number>
								</S.PhoneContent>
							</S.Contact>
							{/* <S.HourPhone>{phone.hour}</S.HourPhone> */}
						</S.Contacts>
					)}

					{whatsapp && (
						<S.Contacts>
							<S.Contact>
								<S.Whats />
								<S.PhoneContent href={whatsapp.link} target="_blank">
									<S.Number>{whatsapp.label}</S.Number>
									{/* <P>{whatsapp.hour}</P> */}
								</S.PhoneContent>
							</S.Contact>
						</S.Contacts>
					)}
				</div>
				<S.Separator />
				<S.AttendanceHours dangerouslySetInnerHTML={{ __html: schedule }} />
			</S.Modal>
		</S.Overlay>
	);
};

export default Modal;
