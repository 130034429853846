export default (typeSlug, slug = '') => {
	switch (typeSlug) {
		case 'clinic-conditions':
			return {
				slug: `/condicoes-clinicas/${slug}`,
				title: 'Condições clínicas',
			};
		case 'drugs':
			return { slug: '/medicamentos', title: 'Medicamentos' };
		case 'faq':
			return {
				slug: `/perguntas-frequentes/${slug}`,
				title: 'Perguntas frequentes',
			};
		case 'insurances':
			return { slug: '/convenios', title: 'Convênios' };
		case 'mobile-services':
			return { slug: '/atendimento-movel', title: 'Atendimento móvel' };
		case 'units':
			return { slug: '/unidades', title: 'Unidades' };
		case 'posts':
			return { slug: `/blog/${slug}`, title: 'Blog' };

		default:
			return false;
	}
};
