module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#e40b4b","theme_color":"#e40b4b","display":"minimal-ui","icon":"src/assets/shortcut-mobile.png","legacy":false,"include_favicon":false,"cache_busting_mode":"query","theme_color_in_head":true,"cacheDigest":"df8f79fa8cf692bdd34edd40bce5360e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TMCBF9Z","includeInDevelopment":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
