import styled from 'styled-components';
import { Link } from 'gatsby';

import { media, shadow, spacing, toRem, zIndex } from '@styles/Theme';

import { Wrapper as LWrapper } from '@components/Layout/styles';
import Button from '@components/Button';

import logoSmall from '@images/logo-fleury-small.svg';
import logoLarge from '@images/logo-fleury.svg';

export const Container = styled.header`
	position: fixed;
	top: 0;
	z-index: ${zIndex.max};
	background-color: white;
	box-shadow: ${shadow};

	@media (${media.desktop}) {
		box-shadow: none;
	}
`;

export const HeaderContainer = styled(LWrapper)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: ${spacing(9)};

	a {
		margin-left: auto;
	}
`;

export const LogoContainer = styled.div`
	@media (${media.mobileAndTablet}) {
		margin-left: ${spacing(5)};
	}
`;

export const Logo = styled(Link)`
	display: block;
	width: ${toRem(110)};
	height: ${toRem(24)};
	background-image: url(${logoSmall});
	background-repeat: no-repeat;
	background-size: contain;

	@media (${media.desktop}) {
		width: ${toRem(254)};
		height: ${toRem(32)};
		margin-top: ${toRem(-4)};
		background-image: url(${logoLarge});
	}
`;

export const SearchInput = styled.div`
	position: relative;
	flex: 1;
	margin: ${spacing(0, 4)};

	@media (${media.mobileAndTablet}) {
		display: none;
	}
`;

export const MobileHideButton = styled(Button)`
	@media (${media.mobileOnly}) {
		display: none;
	}
`;
