import React from 'react';
import PropTypes from 'prop-types';

import { ChevronRightIcon } from '@components/Icons';

import * as S from './styles';

const Link = ({ external, label, withIcon, to, light, prefix, ...rest }) => {
	return (
		<>
			{external ? (
				<S.ExternalLink
					href={to}
					light={light ? 'light' : undefined}
					rel={external && 'noopener noreferrer'}
					target={external ? '_blank' : '_self'}
					{...rest}
				>
					<span>
						{prefix && prefix}
						{label}
						{withIcon && <ChevronRightIcon size={24} />}
					</span>
				</S.ExternalLink>
			) : (
				<S.InnerLink to={to} light={light ? 'light' : undefined} {...rest}>
					<span>
						{prefix && prefix}
						{label}
						{withIcon && <ChevronRightIcon size={24} />}
					</span>
				</S.InnerLink>
			)}
		</>
	);
};

Link.propTypes = {
	external: PropTypes.bool,
	label: PropTypes.string.isRequired,
	light: PropTypes.bool,
	prefix: PropTypes.node,
	to: PropTypes.string.isRequired,
	withIcon: PropTypes.bool,
};

Link.defaultProps = {
	external: false,
	light: false,
	prefix: undefined,
	withIcon: false,
};

export default Link;
